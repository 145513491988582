import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Related } from 'src/components/Post'

export default function CategoryPosts({ categories }) {
  const {
    allPostSummaries: { nodes: posts },
  } = useStaticQuery(graphql`
    query StaticAllWpPost {
      allPostSummaries: allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          ...PostSummaryFields
        }
        totalCount
      }
    }
  `)

  const filteredPosts = posts.filter((post: any) =>
    post.categories.nodes.filter((category: any) =>
      categories.includes(category)
    )
  )

  const slicedPosts = filteredPosts.slice(0, 3)

  return <Related title="Category highlights" posts={slicedPosts} />
}
