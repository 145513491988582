import React from 'react'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Heading } from 'src/components/Typography'

import { Card } from 'src/components/Posts'

const PostList = styled.div`
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
  }
`

export default function Related({ title, posts }) {
  if (Array.isArray(posts) && posts.length > 0) {
    return (
      <Container
        background={{
          kind: 'colour',
          colour: { desktop: 'var(--beige)', mobile: 'var(--beige)' },
          divider: 'round',
        }}
        padding={{
          desktop: [{ direction: ['bottom'], value: 120, unit: 'px' }],
          mobile: [
            { direction: ['top'], value: 60, unit: 'px' },
            { direction: ['bottom'], value: 160, unit: 'px' },
          ],
        }}
      >
        <ColumnGrid>
          <Heading
            $preset="h4"
            as="h3"
            $colour={{ desktop: 'var(--black)', mobile: 'var(--black)' }}
            $align={{ desktop: 'left', mobile: 'left' }}
            css={`
              grid-column: 1 / -1;
              margin: 0 0 24px;
              padding: 0 0 0 24px;
              position: relative;

              &::before {
                background-color: var(--turquoise300);
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                height: 36px;
                width: 4px;

                transform: translate3d(0, -50%, 0);
              }

              @media (min-width: 800px) {
                grid-column: 2 / 12;
              }
            `}
          >
            {title}
          </Heading>
          <PostList>
            {posts?.map(post => (
              <Card key={post?.id} data={post} size="medium" />
            ))}
          </PostList>
        </ColumnGrid>
      </Container>
    )
  }

  return null
}
