import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Container, ColumnGrid } from 'src/components/Layout'
import { ListName, Card } from '.'

export const FeaturedContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  margin: 0 0 32px;

  @media (min-width: 800px) {
    grid-column: 2 / 7;
    grid-row: 2 / 3;
    margin: 0;

    position: sticky;
    /* top: calc(90px + 80px); */
    top: calc(90px + 20px);
    left: 0;
    align-self: start;
  }
`

export const PostList = styled.ul`
  grid-column: 1 / -1;
  grid-row: 3 / 4;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  list-style: none;
  margin: 0;
  padding: calc(clamp(28px, 4.0625vw, 78px) + 24px) 0 0;

  @media (min-width: 800px) {
    grid-column: 7 / 12;
    grid-row: 2 / 3;
  }
`

export default function LatestList({ zIndex, top }) {
  const {
    // featuredPosts,
    latestPosts,
  } = useStaticQuery<GatsbyTypes.LatestPostsQuery>(graphql`
    # query LatestPosts {
    #   latestPosts: allWpPost(
    #     sort: { fields: date, order: DESC }
    #     filter: {
    #       categories: { nodes: { elemMatch: { name: { ne: "Featured" } } } }
    #     }
    #   ) {
    #     nodes {
    #       ...PostSummaryFields
    #     }
    #   }
    # }
    query LatestPosts {
      latestPosts: allWpContentNode(
        sort: { fields: date, order: DESC }
        filter: { nodeType: { in: ["CaseStudy", "Post"] } }
      ) {
        nodes {
          __typename
          ...PostSummaryFields
          ...CaseStudySummaryFields
        }
      }
    }
  `)

  const [first, ...rest] = latestPosts.nodes

  return (
    <Container
      background={{
        kind: 'colour',
        colour: { desktop: 'var(--beige)', mobile: 'var(--beige)' },
        divider: 'flat',
      }}
      zIndex={zIndex}
      top={top}
      padding={{
        desktop: [
          { direction: ['top'], value: 80, unit: 'px' },
          { direction: ['bottom'], value: 150, unit: 'px' },
        ],
        mobile: [
          { direction: ['top'], value: 50, unit: 'px' },
          { direction: ['bottom'], value: 150, unit: 'px' },
        ],
      }}
      // css={`
      //   padding: 80px 0 150px;
      // `}
    >
      <ColumnGrid>
        <FeaturedContainer>
          <ListName>Latest</ListName>
          {first && <Card data={first} size="large" />}
        </FeaturedContainer>
        <PostList>
          {rest.map(post => (
            <li
              key={post.id}
              css={`
                min-width: 0;
              `}
            >
              <Card data={post} size="small" />
            </li>
          ))}
        </PostList>
      </ColumnGrid>
    </Container>
  )
}

export const fragment = graphql`
  fragment LatestPostsFields on WpContent_Acf_Layouts_LatestPosts {
    acfeFlexibleLayoutTitle
  }
`
