import React from 'react'
import styled from 'styled-components'
import { ColumnGrid } from 'src/components/Layout'
import { Heading } from 'src/components/Typography'
import { Image } from 'src/components/Elements'

const Container = styled.div`
  background-color: var(--purple900);
`

const Header = styled.div`
  grid-column: 1 / -1;

  background-color: var(--purple900);
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 90px 0 80px;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
    padding: 160px 0 80px;
  }
`

const Body = styled.div`
  grid-column: 1 / -1;

  background-color: var(--beige);
  margin: -80px 0 0;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
  }
`

export default function Cover({ categories, title, date, cover }) {
  return (
    <>
      <Container>
        <ColumnGrid>
          <Header>
            <Heading
              $preset="h6"
              as="span"
              $colour={{
                desktop: 'var(--squash300)',
                mobile: 'var(--squash300)',
              }}
              $align={{ desktop: 'center', mobile: 'center' }}
              css={`
                margin: 0 0 16px;
                text-transform: uppercase;
              `}
            >
              {categories.reduce(
                (acc, curr, idx) =>
                  idx < categories.length - 1
                    ? `${acc}${curr.name}, `
                    : `${acc}${curr.name}`,
                ''
              )}
            </Heading>
            <Heading
              $preset="h2"
              as="h2"
              $colour={{ desktop: 'var(--white)', mobile: 'var(--white)' }}
              $align={{ desktop: 'center', mobile: 'center' }}
              css={`
                margin: 0 0 16px;
              `}
            >
              {title}
            </Heading>
            <Heading
              $preset="h5"
              as="span"
              $colour={{
                desktop: 'var(--purple200)',
                mobile: 'var(--purple200)',
              }}
              $align={{ desktop: 'center', mobile: 'center' }}
              css={`
                margin: 0 0 8px;
                @media (min-width: 800px) {
                  margin: 0 0 40px;
                }
              `}
            >
              {new Date(date).toLocaleString('en-CA', { dateStyle: 'long' })}
            </Heading>
          </Header>
        </ColumnGrid>
      </Container>
      <Container
        css={`
          background-color: var(--beige);
        `}
      >
        <ColumnGrid>
          <Body>
            <Image source={cover?.image} css={``} />
          </Body>
        </ColumnGrid>
      </Container>
    </>
  )
}
