import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  fontBold,
  fontRoman,
  sizeBodyTwo,
  h5,
  h6,
  bodyTwo,
} from 'src/lib/typography'
import { vwRatio, maxRatio } from 'src/lib/styles'
import { UnstyledLink } from 'src/components/Elements'

const Container = styled.article``

const Header = styled.div`
  background-color: var(--placeholder);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 24px;
  overflow: hidden;
  position: relative;
`

const ImgLink = styled(UnstyledLink)`
  height: 100%;
  width: 100%;
`

const CardImage = styled(GatsbyImage)`
  /* removes 4px bottom */
  display: block;
  height: 100%;
  width: 100%;
`

const DateContainer = styled.div`
  background-color: var(--purple400);
  border: none;
  border-radius: 0 0 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  width: clamp(40px, ${60 * vwRatio}vw, ${60 * maxRatio}px);
  height: clamp(40px, ${60 * vwRatio}vw, ${60 * maxRatio}px);
  /* width: 40px; */
  /* height: 40px; */

  @media (min-width: 800px) {
    /* width: 60px; */
    /* height: 60px; */
  }
`

const DateText = styled.span`
  color: var(--white);
  ${fontBold}
  font-size: clamp(9px, ${14 * vwRatio}vw, ${14 * maxRatio}px);
  line-height: clamp(14px, ${18 * vwRatio}vw, ${18 * maxRatio}px);
  text-transform: uppercase;
`

const DayText = styled.span`
  color: var(--white);
  ${fontRoman}
  font-size: clamp(12px, ${20 * vwRatio}vw, ${20 * maxRatio}px);
  line-height: clamp(16px, ${28 * vwRatio}vw, ${28 * maxRatio}px);
`

const Body = styled.div``

const Category = styled.span`
  ${fontBold}
  ${sizeBodyTwo}
  color: var(--purple300);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin: 0 0 8px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  text-transform: uppercase;
`

const Title = styled.h4`
  ${({ $size }) => {
    switch ($size) {
      case 'large':
        return h5
      case 'medium':
        return h5
      case 'small':
        return h6
      default:
        throw new Error('[Error] Unhandled Card title size.')
    }
  }};
  color: var(--purple900);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 0 0 16px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
`

const Description = styled.p`
  ${({ $size }) => {
    switch ($size) {
      case 'large':
        return bodyTwo
      case 'medium':
        return bodyTwo
      case 'small':
        return bodyTwo
      default:
        throw new Error('[Error] Unhandled card description size.')
    }
  }}
  color: var(--black);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default function Card({ data, size }) {
  const dateMonth = new Date(data?.date).toLocaleString('en-CA', {
    month: 'short',
  })
  const dateDate = new Date(data?.date).toLocaleString('en-CA', {
    day: 'numeric',
  })

  const sources =
    data?.__typename === 'WpPost'
      ? {
          small: withArtDirection(
            data?.cover?.image?.mobile?.localFile?.childImageSharp?.sm,
            [
              {
                media: '(min-width: 800px)',
                image:
                  data?.cover?.image?.desktop?.localFile?.childImageSharp?.sm,
              },
            ]
          ),
          medium: withArtDirection(
            data?.cover?.image?.mobile?.localFile?.childImageSharp?.md,
            [
              {
                media: '(min-width: 800px)',
                image:
                  data?.cover?.image?.desktop?.localFile?.childImageSharp?.md,
              },
            ]
          ),
          large: withArtDirection(
            data?.cover?.image?.mobile?.localFile?.childImageSharp?.lg,
            [
              {
                media: '(min-width: 800px)',
                image:
                  data?.cover?.image?.desktop?.localFile?.childImageSharp?.lg,
              },
            ]
          ),
        }
      : {
          small: data?.ACF?.meta?.media?.localFile?.childImageSharp?.sm,
          medium: data?.ACF?.meta?.media?.localFile?.childImageSharp?.md,
          large: data?.ACF?.meta?.media?.localFile?.childImageSharp?.lg,
        }

  return (
    <Container>
      <Header>
        <ImgLink to={data?.uri}>
          <CardImage image={sources[size]} alt={'Card Image'} />
        </ImgLink>
        <DateContainer>
          <DateText>{dateMonth}</DateText>
          <DayText>{dateDate}</DayText>
        </DateContainer>
      </Header>
      <Body>
        <Category>
          {data?.__typename === 'WpPost' &&
            data?.categories?.nodes?.map((node, index) => {
              if (index < data?.categories?.nodes.length - 1) {
                return (
                  <UnstyledLink key={node.id} to={node.uri}>
                    {`${node.name}, `}
                    {/* `${node.name}, ` */}
                  </UnstyledLink>
                )
              } else
                return (
                  <UnstyledLink key={node.id} to={node.uri}>
                    {`${node.name}`}
                    {/* `${node.name}` */}
                  </UnstyledLink>
                )
            })}
          {data?.__typename === 'WpCaseStudy' && (
            <UnstyledLink to="/case-studies">Case Study</UnstyledLink>
          )}
        </Category>
        <UnstyledLink to={data?.uri}>
          <Title $size={size}>{data?.title}</Title>
        </UnstyledLink>
        <Description $size={size}>{data?.ACF?.meta?.description}</Description>
      </Body>
    </Container>
  )
}

export const fragment = graphql`
  fragment CardImageSmFields on WpMediaItem {
    altText
    localFile {
      childImageSharp {
        sm: gatsbyImageData(
          backgroundColor: "#fefefe"
          layout: CONSTRAINED
          placeholder: NONE
          quality: 90
          aspectRatio: 1.15
        )
      }
    }
  }
  fragment CardImageMdFields on WpMediaItem {
    altText
    localFile {
      childImageSharp {
        md: gatsbyImageData(
          backgroundColor: "#fefefe"
          layout: CONSTRAINED
          placeholder: NONE
          quality: 90
          aspectRatio: 1.6
        )
      }
    }
  }
  fragment CardImageLgFields on WpMediaItem {
    altText
    localFile {
      childImageSharp {
        lg: gatsbyImageData(
          backgroundColor: "#fefefe"
          layout: CONSTRAINED
          placeholder: NONE
          quality: 90
          aspectRatio: 1.4
        )
      }
    }
  }

  fragment PostSummaryFields on WpPost {
    __typename
    id
    title
    date
    uri
    cover {
      image {
        desktop {
          ...DesktopImageFields
          ...CardImageSmFields
          ...CardImageMdFields
          ...CardImageLgFields
        }
        mobile {
          ...MobileImageFields
          ...CardImageSmFields
          ...CardImageMdFields
          ...CardImageLgFields
        }
      }
    }
    categories {
      nodes {
        id
        name
        uri
      }
    }
    ACF {
      meta {
        title
        description
      }
    }
  }

  fragment CaseStudySummaryFields on WpCaseStudy {
    __typename
    id
    title
    date
    uri
    tags {
      nodes {
        id
        name
        uri
      }
    }
    ACF {
      meta {
        title
        description
        media {
          ...CardImageSmFields
          ...CardImageMdFields
          ...CardImageLgFields
        }
      }
    }
  }
`
