import React from 'react'
import { graphql } from 'gatsby'

import { CaseStudies, CategoryPosts } from '.'

export default function CategoryList({ data, zIndex, top }) {
  if (data?.kind === 'caseStudy') return <CaseStudies />

  if (data?.kind === 'post')
    return <CategoryPosts categories={JSON.parse(data?.category)} />
}

export const fragment = graphql`
  fragment CategoryPostsFields on WpContent_Acf_Layouts_CategoryPosts {
    acfeFlexibleLayoutTitle
    kind
    category
  }
`
