import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Related } from 'src/components/Post'

export default function CaseStudies() {
  const {
    allCaseStudySummaries: { nodes: caseStudies },
  } = useStaticQuery(graphql`
    query StaticAllWpCaseStudy {
      allCaseStudySummaries: allWpCaseStudy(
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          ...CaseStudySummaryFields
        }
        totalCount
      }
    }
  `)

  const summaryCaseStudies = caseStudies.slice(0, 3)

  return <Related title="Related case studies" posts={summaryCaseStudies} />
}
