import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Heading, Body } from 'src/components/Typography'

const Container = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  margin: 0 0 32px;

  @media (min-width: 800px) {
    grid-column: 2 / 6;
  }
`

export default function Source({ source }) {
  return (
    <Container>
      <Heading
        $preset="h6"
        $colour={{ desktop: 'var(--purple300)', mobile: 'var(--purple300)' }}
        $align={{ desktop: 'left', mobile: 'left' }}
        css={`
          margin: 0 0 8px;
        `}
      >
        About the source
      </Heading>
      <Body
        $preset="bodyTwo"
        as="div"
        $colour={{ desktop: 'var(--black)', mobile: 'var(--black)' }}
        $align={{ desktop: 'left', mobile: 'left' }}
        dangerouslySetInnerHTML={{ __html: source?.description }}
      />
    </Container>
  )
}

export const fragment = graphql`
  fragment SourceFields on WpPost {
    source {
      description
    }
  }
`
