import styled from 'styled-components'
import { h3 } from 'src/lib/typography'

export const ListName = styled.h3`
  ${h3}
  color: var(--black);
  margin: 0 0 24px;
  padding: 0 0 0 24px;
  text-align: left;

  position: relative;

  &::before {
    background-color: var(--turquoise300);
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    height: var(--font-size);
    width: 4px;

    transform: translate3d(0, -50%, 0);
  }
`
