import React from 'react'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Source, Share } from 'src/components/Post'

export default function Footer({ source, url }) {
  return (
    <Container
      background={{
        kind: 'colour',
        colour: {
          desktop: 'var(--beige)',
          mobile: 'var(--beige)',
        },
        divider: 'flat',
      }}
    >
      <ColumnGrid>
        {source?.description && <Source source={source} />}
        <Share url={url} />
      </ColumnGrid>
    </Container>
  )
}
