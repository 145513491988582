import React from 'react'
import styled from 'styled-components'
import { Heading } from 'src/components/Typography'

const Container = styled.div`
  grid-column: 1 / 6;
  grid-row: 2 / 3;

  background-color: var(--turquoise300);
  border: none;
  border-radius: 24px 0 48px 0;
  padding: 24px 28px;

  @media (min-width: 800px) {
    grid-column: 9 / 12;
    grid-row: 1 / 2;
  }
`

const ShareUrl = styled.a.attrs(({ href }: { href: string }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  href,
}))`
  color: var(--purple900);
`

const Icons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Fb = styled(require('src/images/logos/facebook-share.svg'))`
  margin: 0 12px 0 0;
`
const Tw = styled(require('src/images/logos/twitter-share.svg'))`
  margin: 0 12px 0 0;
`
const Li = styled(require('src/images/logos/linkedin-share.svg'))`
  margin: 0 12px 0 0;
`

const Pi = styled(require('src/images/logos/pinterest-share.svg'))`
  margin: 0 12px 0 0;
`

const Em = styled(require('src/images/icons/email-share.svg'))``

export default function Share({ url }) {
  const uri = 'https://colloqui-interactive.com/test-uri'

  return (
    <Container>
      <Heading
        $preset="blogCategory"
        as="h6"
        $colour={{ desktop: 'var(--white)', mobile: 'var(--white)' }}
        $align={{ desktop: 'left', mobile: 'left' }}
        css={`
          margin: 0 0 8px;
        `}
      >
        Share this article:
      </Heading>
      <Icons>
        <ShareUrl
          href={`http://www.facebook.com/sharer.php?u=${encodeURIComponent(
            url
          )}`}
        >
          <Fb />
        </ShareUrl>
        {/* <Tw /> */}
        <ShareUrl
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            url
          )}`}
        >
          <Li />
        </ShareUrl>
        {/* <Pi /> */}
        <ShareUrl
          href={`mailto:?subject=${encodeURIComponent(
            'Check out this article from Colloqui: the interactive company!'
          )}&body=I thought you might like this super informative article: ${url}`}
        >
          <Em />
        </ShareUrl>
      </Icons>
    </Container>
  )
}
